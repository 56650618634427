// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-reel-tsx": () => import("./../../../src/pages/reel.tsx" /* webpackChunkName: "component---src-pages-reel-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

